import http from '../util/http.js'

export const InstituteList = (name,page,pageSize = 10) => http.get('/institute/list',{name,page,pageSize})
export const InstituteListData = () => http.get('/institute/listData',{})
export const UpdateInstituteData = (institutionInfo) => http.post("/institute/updateInstitute",institutionInfo)
export const InstituteMailList = (id) => http.get('/institute/instituteMailList',{id})
export const SaveOrUpdateList = (params) => http.postJSON('/institute/saveOrUpdateList',params)
export const Delete = (id) => http.post('/institute/delete',{id})
export const InstituteSave = (params) => http.post('/institute/save',params)

